import { render, staticRenderFns } from "./ui-globals-actions.vue?vue&type=template&id=242aaaa9&lang=pug"
import script from "./ui-globals-actions.vue?vue&type=script&lang=js"
export * from "./ui-globals-actions.vue?vue&type=script&lang=js"
import style0 from "./ui-globals-actions.vue?vue&type=style&index=0&id=242aaaa9&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.29_babel-core@7.0.0-bridge.0_@babel+core@7.24.7__css_lnatwmq5ayeyuqvdbpq5frmufi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports