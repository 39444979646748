import Vue from 'vue'
import VueRouter from 'vue-router'
import Acl from 'vue-acl'

import StatisticsRoutes from '@/components/Statistics/routes.js'
import ContextRoutes from '@/components/Context/routes.js'
import CourseBlocksRoutes from '@/components/CourseBlocks/routes.js'
import CompaniesRoutes from '@/components/Companies/routes.js'
import CompanyUserGroupRoutes from '@/components/CompanyUserGroup/routes.js'
import CompetenciesRoutes from '@/components/Competencies/routes.js'
import CoursesRoutes from '@/components/Courses/routes.js'
import CoursesTermsRoutes from '@/components/CourseTerms/routes.js'
import DashboardRoutes from '@/components/Dashboard/routes.js'
import JobsRoutes from '@/components/CourseCategory/routes.js'
import ClassRoutes from '@/components/Classes/routes.js'
import TypesRoutes from '@/components/CourseType/routes.js'
import AppUsersRoutes from '@/components/AppUser/routes.js'
import ProgramsRoutes from '@/components/Programs/routes.js'
import ProductsRoutes from '@/components/Products/routes.js'
import LoginRoutes from '@/components/Authentification/routes.js'
import DemonstrationVideo from '@/components/DemonstrationVideo/routes.js'
import CreditsRoutes from '@/components/Credits/routes.js'
import LegalRoutes from '@/components/Legal/routes.js'
import { concat, defaultsDeep, get } from 'lodash'
import api from '@/api'
import store from './store/index.js'
import i18n from '@/i18n'

Vue.use(VueRouter)

const defaultRouteSettings = {
  meta: {
    permission: 'grains',
    fail: '/',
  },
}
const routes = concat(
  ContextRoutes,
  DashboardRoutes,
  CourseBlocksRoutes,
  CompaniesRoutes,
  CompanyUserGroupRoutes,
  CompetenciesRoutes,
  CoursesRoutes,
  JobsRoutes,
  ClassRoutes,
  TypesRoutes,
  AppUsersRoutes,
  ProgramsRoutes,
  ProductsRoutes,
  CoursesTermsRoutes,
  LoginRoutes,
  DemonstrationVideo,
  CreditsRoutes,
  StatisticsRoutes,
  LegalRoutes,
)
routes.forEach(el => {
  defaultsDeep(el, defaultRouteSettings)
})

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})

Vue.use(Acl, { router, init: 'notLogged' })

router.afterEach((to, from) => {
  api.removeAll('CourseBlock')
  api.removeAll('CourseNode')
  api.removeAll('LessonPage')
  api.removeAll('Lesson')
  if (store) {
    store.commit('SET_CURRENT_ROUTE', router.currentRoute)
    store.commit('SET_PREVIOUS_ROUTE', from)
  }
  if (to.name !== 'Login') {
    window.sessionStorage.setItem('main.request.url', to.path)
  }
})
router.beforeEach((to, from, next) => {
  if (store) {
    store.commit('removeNonPersistantAlerts')
    store.commit('CLEAR_CUSTOM_BREAD')
  }
  if (to.query.idT && to.query.idU) {
    const idToken = to.query.idT
    const idUser = to.query.idU
    // pousse dans local
    window.localStorage.setItem('id_token', idToken)
    window.localStorage.setItem('id_user', idUser)
    delete to.query.idT
    delete to.query.idU

    router.push({ path: to.path })
  }
  // Génération par défault des titles, pour les pages d'édition, cf Mixins/Edit.js
  const title = []
  if (store) {
    title.push(store.getters['context/get']('name'))
  }
  if (get(to, 'name') && i18n.te && i18n.te(`routes.${to.name}`)) {
    title.push(i18n.tc(`routes.${to.name}`)) // app.$tc(app.$tc(`routes.${to.name}`))
  } else {
    title.push('Administration')
  }
  document.title = title.join(' - ')
  next()
})
export default router
