import utils from '@/helpers/utils'
import store from '@/api'
import { isBoolean, isString } from 'lodash'

export default {
  filters: {
    translateWithContextLabel (str) {
      return this.translateWithContextLabel(str)
    },
  },
  methods: {
    translateWithContextLabel (str) {
      if (!isString(str)) {
        return str
      }
      // str = str.replace(/a/g, this.contextName)
      str = str.replace(/{{\s*contextName\s*}}/g, this.contextName)
      str = str.replace(/{{\s*name\s*}}/g, this.contextName)
      str = str.replace(/{{\s*contextGauge\s*}}/g, this.contextGaugeLabel)
      str = str.replace(/{{\s*gauge\s*}}/g, this.contextGaugeLabel)
      str = str.replace(/{{\s*contextLevel\s*}}/g, this.contextLevelLabel)
      str = str.replace(/{{\s*level\s*}}/g, this.contextLevelLabel)
      str = str.replace(/{{\s*contextScore\s*}}/g, this.contextScoreLabel)
      str = str.replace(/{{\s*score\s*}}/g, this.contextScoreLabel)
      return str
    },
  },
  computed: {
    USE_REGISTER () {
      return this.$store.getters['context/get']('settings.allowAccessToRegisterFeature') || false
    },
    USE_BUY () {
      return this.$store.getters['context/get']('settings.allowAccessToBuyFeature') || false
    },
    USE_REGISTER_BY_INVITATION () {
      return this.CAN_USE_INTERNAL_LOGIN && this.$store.getters['context/get']('settings.allowAccessToInvitationCodeFeature')
    },
    CAN_USE_INTERNAL_LOGIN () {
      return this.$store.getters['context/get']('settings.useInternalAuthentification') ?? true
    },
    contextName () {
      return this.$store.getters['context/get']('name')
    },
    contextId () {
      return this.$store.getters['context/id']
    },
    contextCanSeeStudentNames () {
      return this.$store.getters['context/get']('settings.allowUserToSetFirstname') === false && this.$store.getters['context/get']('settings.allowUserToSetLastname') === false
    },
    contextMetasKeysFroala () {
      return this.$store.getters['context/get']('metas.keys.froala') || ''
    },
    contextLevelLabel () {
      return this.$store.getters['context/get']('metas.levelLabel')
    },
    contextGaugeLabel () {
      return this.$store.getters['context/get']('metas.gaugeLabel')
    },
    contextScoreLabel () {
      return this.$store.getters['context/get']('metas.scoreLabel')
    },
    contextAllowAccessToCertificationFeature () {
      if (this.is_generic) {
        return true
      }
      return this.$store.getters['context/get']('settings.allowAccessToCertificationFeature')
    },
    contextAllowEditGroupFeature () {
      return this.$store.getters['context/get']('settings.allowEditGroupFeature') ?? true
    },
    contextAllowLoginAs () {
      return this.$store.getters['context/get']('settings.allowAccessToLoginAsFeature') ?? false
    },
    contextAuthScreenBackground () {
      const id = this.$store.getters['context/get']('assets.authScreenBackgroundId')
      const imgUrl = utils.getFilePath(id)
      return id ? imgUrl : false
    },
    contextFavicon () {
      const id = this.$store.getters['context/get']('assets.boFaviconId')
      const imgUrl = utils.getFilePath(id)
      return id ? imgUrl : false
    },
    contextLogoBadge () {
      const id = this.$store.getters['context/get']('assets.foLogoBadgeId')
      const imgUrl = utils.getFilePath(id)
      return id ? imgUrl : false
    },
    contextBoShowContributionBox () {
      const value = this.$store.getters['context/get']('metas.boShowContributionBox')
      if (!isBoolean(value)) {
        return true
      }
      return value
    },
    contextBoRelancePrimaryColor () {
      return this.$store.getters['context/get']('metas.boRelancePrimaryColor')
    },
    contextBoRelanceSecondaryColor () {
      return this.$store.getters['context/get']('metas.boRelanceSecondaryColor')
    },
    contextBoRelanceQuaternaryColor () {
      return this.$store.getters['context/get']('metas.boRelanceQuaternaryColor')
    },
    contextBoRelanceUseIllustration () {
      return this.$store.getters['context/get']('metas.boRelanceUseIllustration') !== false
    },
    contextPrimaryColor () {
      return this.$store.getters['context/get']('metas.boPrimaryColor')
    },
    contextPrimaryTextColor () {
      return this.$store.getters['context/get']('metas.boPrimaryTextColor')
    },
    contextSecondaryColor () {
      return this.$store.getters['context/get']('metas.boSecondaryColor')
    },
    contextSecondaryTextColor () {
      return this.$store.getters['context/get']('metas.boSecondaryTextColor')
    },
    contextFrontoffice () {
      return this.$store.getters['context/get']('hosts.frontoffice')
    },
    contextCertification () {
      return this.$store.getters['context/get']('hosts.certification')
    },
    contextBackoffice () {
      return this.$store.getters['context/get']('hosts.backoffice')
    },
    contextApiUsed () {
      return this.$store.getters['context/apiUsed']
    },
    contextGenericCompetenciesReportingLevel () {
      return this.$store.getters['context/get']('competencies.genericCompetencies.reportingLevel') || 1
    },
    contextSplashScreensDefinitions () {
      return store.getMapperByName('Context').splashScreensSizes
    },
    touchIconsDefinitions () {
      return store.getMapperByName('Context').touchIcons
    },
  },
}
