import { Record, Schema } from 'js-data'
import { addActions } from 'js-data-http'
import store from '@/api'
import { cloneDeep, compact, get, isNaN, isUndefined } from 'lodash'
import resolveDeps from '../getDeps.js'
import router from '@/router'
import { EXERCICES } from '../enums/exercices.js'
import { CONTENTS } from '../enums/contents.js'
import { WIDGETS } from '../enums/widgets.js'

const exos = Object.values(EXERCICES)
const contents = Object.values(CONTENTS)
const widgets = Object.values(WIDGETS)

const CourseBlockSchema = new Schema({
  title: 'CourseBlockShemaTitle',
  description: 'Schema for CourseBlock Records.', // optional
  type: 'object',
  additionalProperties: true,
  properties: {
    // contextId: {
    //   type: ['string', 'null']
    // },
    isGeneric: {
      type: 'boolean',
    },
    contextId: {
      type: ['null', 'string'],
    },
    tags: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    flags: {
      type: 'array',
      items: {
        type: 'string',
        // enum: ['Brouillon', 'A relire', 'Terminé', 'A compléter', 'A valider', 'A corriger', 'course', 'resource']
        enum: [
          'En cours',
          'A valider',
          'Terminé',
          'A revoir',
          'Mise en page',
          'Mobile',
          '+ consigne',
          '+ énoncé',
          '+ exercice',
          '+ feedback',
          '+ glossaire',
          '+ image',
          '+ indice',
          '+ nœud',
          '+ photo',
          '+ son',
          '+ tableau',
          '+ variables',
          '+ vidéos',
          '+ widget',
        ],
        additionalItems: true,
      },
    },
    type: {
      type: 'string',
      enum: ['exercice', 'slide', 'tincan'],
    },
    title: {
      type: 'string',
      minLength: 3,
      form: {
        rules: [
          // { required: true, message: 'Please input Course name', trigger: 'blur' },
          // { min: 2, max: 10, message: 'Length should be 4 to 10', trigger: 'blur' }
        ],
      },
    },
    reference: {
      type: 'string',
    },
    metas: {
      type: 'object',
      properties: {
        difficulty: {
          type: ['number', 'null'],
        },
      },
    },
    content: {
      type: 'object',
      properties: {
        table: {
          type: 'array',
          items: {
            type: ['array'],
            items: {
              type: 'object',
              properties: {
                type: {
                  type: ['string', 'null'],
                  enum: ['text', 'image', 'exercice', '', null],
                },
                options: {
                  type: 'object',
                  properties: {
                    class: {
                      type: 'array',
                      items: {
                        type: 'string',
                      },
                    },
                  },
                  additionalProperties: true,
                },
              },
              additionalProperties: true,
            },
          },
        },
        interactiveImage: {
          type: 'object',
          properties: {
            appFile: {
              type: ['object', 'null'],
              id: {
                type: ['string', 'null'],
              },
              libraryId: {
                type: ['string', 'null'],
              },
            },
            blocks: {
              type: ['array', 'null'],
              items: {
                type: 'object',
                properties: {
                  x: {
                    type: 'string',
                  },
                  y: {
                    type: 'string',
                  },
                  exo: {
                    type: 'string',
                  },
                  origin: {
                    type: 'object',
                    properties: {
                      x: {
                        type: 'string',
                      },
                      y: {
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        statement: {
          type: 'object',
          properties: {
            html: {
              type: 'string',
            },
            settings: {
              type: 'object',
              additionalProperties: true,
            },
            additionalProperties: true,
          },
        },
        tincan: {
          type: 'object',
          properties: {
            url: {
              type: 'string',
            },
            maxScore: {
              type: ['integer', 'null'],
            },
            tincanAttachmentId: {
              type: ['string', 'null'],
            },
            additionalProperties: true,
          },
        },
        sections: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              title: {
                type: ['string', 'null'], // optional
              },
              description: {
                type: ['string', 'null'], // optional
              },
              rows: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    type: {
                      type: 'string',
                      enum: [...exos, ...contents, ...widgets],
                    },
                    message: {
                      type: 'string',
                    },
                    order: {
                      type: ['string', 'null'],
                    },
                    choices: {
                      type: ['array', 'null'],
                      items: {
                        type: 'object',
                        properties: {
                          value: {
                            type: ['string', 'null'],
                          },
                          appFile: {
                            type: ['object', 'null'],
                            properties: {
                              id: {
                                type: ['string', 'null'],
                              },
                              libraryId: {
                                type: ['string', 'null'],
                              },
                            },
                          },
                        },
                      },
                    },
                    targets: {
                      type: ['array', 'null'],
                      items: {
                        type: 'object',
                        properties: {
                          message: {
                            type: ['string', 'null'],
                          },
                          appFile: {
                            type: ['object', 'null'],
                            properties: {
                              id: {
                                type: 'string',
                              },
                              libraryId: {
                                type: 'string',
                              },
                            },
                          },
                        },
                      },
                    },
                    validate: {
                      type: ['array', 'number', 'object'],
                    },
                    hints: {
                      type: 'array',
                    },
                    // Propriétés pour les contenus éditoriaux
                    title: {
                      type: 'string',
                    },
                    appFile: {
                      type: ['object', 'null'],
                      properties: {
                        id: {
                          type: 'string',
                        },
                        libraryId: {
                          type: 'string',
                        },
                      },
                    },
                    settings: {
                      type: 'object',
                      properties: {},
                      additionalProperties: true,
                    },
                    data: {
                      type: 'object',
                      additionalProperties: true,
                    },
                    feedbacks: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {},
                        additionalProperties: true,
                      },
                    },
                  },
                },
              },
            },
          },
        },
        datasets: {
          type: 'array',
          items: {
            type: 'object',
            additionalProperties: true,
          },
        },
        datasetsComputed: {
          type: 'array',
          items: {
            type: 'object',
            additionalProperties: true,
          },
        },
      },
    },
    settings: {
      type: 'object',
      properties: {
        timers: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              duration: {
                type: 'integer',
              },
              message: {
                type: 'string',
              },
              action: {
                type: 'object',
                additionalProperties: true,
              },
            },
          },
        },
        feedbacks: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              message: {
                type: 'string',
              },
              conditions: {
                type: ['array'],
                items: {
                  type: 'object',
                  additionalProperties: true,
                },
              },
            },
          },
        },
        hints: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              message: {
                type: 'string',
              },
              action: {
                type: 'object',
                additionalProperties: true,
              },
            },
          },
        },
        template: {
          type: 'object',
          additionalProperties: true,
          properties: {
            backToDashBoard: {
              type: 'boolean',
            },
            hideMainTitle: {
              type: 'boolean',
            },
          },
        },
      },
    },
    categoryId: {
      type: ['string', 'null'],
    },
    competencyId: {
      type: ['string', 'null'],
    },
    attachmentIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    termIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  required: ['title'],
})

export class CourseBlockRecord extends Record {
  affectExerciceFeedbacksToChoice () {
    (this.content?.sections?.[0]?.rows || []).forEach((row, rowIndex) => {
      if (![EXERCICES.QCM, EXERCICES.SELECT].includes(row.type)) return
      (row.feedbacks || []).forEach((feedback, feedbackIndex) => {
        const indexAsString = feedback?.conditions?.[0]?.answers?.[0]
        if (isUndefined(indexAsString)) {
          console.error('answer index not found')
        }
        const index = +indexAsString
        if (isNaN(index)) {
          console.error('answer is not an index')
        }
        const target = row?.choices?.[index]
        if (!target) {
          console.error('answer choice not found at index', index)
        }
        target.feedbacks = target.feedbacks || []
        target.feedbacks.push({
          message: feedback.message,
          preset: 'always',
        })
      })
      row.feedbacks = []
    })
  }

  static affectChoiceFeedbacksToExercice (obj) {
    (obj.content?.sections?.[0]?.rows || []).forEach((row, rowIndex) => {
      if (![EXERCICES.QCM, EXERCICES.SELECT].includes(row.type)) return

      row.feedbacks = (row?.choices || []).map((choice, choiceIndex) => (choice.feedbacks || []).map(feedback => ({
        message: feedback.message,
        conditions: [{
          preset: 'always',
          answers: [`${choiceIndex}`],
        }],
      }))).flat();

      (row?.choices || []).forEach(choice => {
        delete choice.feedbacks
      })
    })
  }

  affectChoiceFeedbacksToExercice () {
    CourseBlockRecord.affectChoiceFeedbacksToExercice(this)
  }

  getEditLink ({ programId, courseId }) {
    const { edit, editFromCourse, editFromProgram } = this.constructor.mapper.paths
    let name = edit
    const params = { id: this.id }
    if (programId && courseId) {
      name = editFromProgram
      params.programId = programId
      params.courseId = courseId
    } else if (courseId) {
      name = editFromCourse
      params.courseId = courseId
    }

    const url = router.resolve({
      name,
      query: {
        isGeneric: Boolean(this.isGeneric),
      },
      params,
    })
    return url
  }

  getStats () {
    const params = {
      rangesStringified: JSON.stringify([
        { min: 0, max: 50 },
        { min: 50.001, max: 75 },
        { min: 75.001, max: 100 },
      ]),
    }
    return this.constructor.mapper.stats(this.id, { params })
      .then(response => cloneDeep(get(response, ['data', 0])),
      ).catch(error => {
        console.error(error)
        return {}
      })
  }

  get editPath () {
    return { name: 'CourseBlockEdit', params: { id: this.id } }
  }

  get exoTypes () {
    window.block = this
    const typesUsed = exos.map(type => {
      if (JSON.search(this, `*/content/sections/rows[type="${type}"]`).length > 0) {
        return type
      }
      return false
    })
    return compact(typesUsed)
  }

  get getDeps () {
    const promises = {
      Course: store.findAll('CourseNode', { filter: { blockId: this.id }, populate: { path: 'course' }, options: { skip: 0, limit: 5 } }, { force: true, raw: true }).then((response) => {
        const courseIds = response.data.map(cn => cn.course._id)
        const courses = store.filter('Course', { where: { _id: { inq: courseIds } } })
        return { data: courses, count: +response.headers['x-model-count'] }
      }),
    }

    return resolveDeps(promises)
  }

  duplicateToContext (contextId) {
    return this.constructor.mapper
      .cloneToContext(this.id, {
        data: { contextId },
        timeout: 60 * 60 * 1000, // 1h = 60 * 60 * 1000
      })
      .then((response) => {
        return response.data
      })
  }
}

export const CourseBlock = store.defineMapper('CourseBlock', {
  schema: CourseBlockSchema,
  endpoint: 'course-blocks',
  paths: {
    list: 'CourseBlockList',
    edit: 'CourseBlockEdit',
    editFromCourse: 'CourseBlockEditFromCourse',
    editFromProgram: 'CourseBlockEditFromProgram',
  },
  relations: {
    belongsTo: {
      CourseCategory: {
        localField: 'category',
        localKey: 'categoryId',
      },
      CourseCompetency: {
        localField: 'competency',
        localKey: 'competencyId',
      },
    },
    hasMany: {
      CourseTerm: {
        loadAllDatasWhenEditing: false,
        localField: 'terms',
        localKey: 'termIds',
      },
      CourseNode: {
        loadAllDatasWhenEditing: false,
        localField: 'nodes',
        localKey: 'blockId',
      },
    },
  },
  recordClass: CourseBlockRecord,
})

addActions({
  stats: {
    pathname: 'stats',
    method: 'GET',
  },
  cloneToContext: {
    pathname: 'duplicate-to-context',
    method: 'POST',
  },
})(CourseBlock)
