const ProgramsList = () => import('./List.vue')
const ProgramsEdit = () => import('./Edit.vue')
const ProgramsGrid = () => import('./Grid.vue')
const ProgramsDetails = () => import('./Details.vue')

const routes = [
  {
    path: '/produits/',
    name: 'ProgramGrid',
    meta: {
      layout: {
        absolute: true,
      },
      permission: 'grains|client',
    },
    component: ProgramsGrid,
  }, {
    path: '/produits/:id',
    name: 'ProgramDetails',
    meta: {
      permission: 'grains|client',
    },
    component: ProgramsDetails,
    props: true,
  }, {
    path: '/program',
    name: 'ProgramList',
    component: ProgramsList,
  }, {
    path: '/program/edit/:id',
    name: 'ProgramEdit',
    component: ProgramsEdit,
    props: true,
  }, {
    path: '/program/edit',
    name: 'ProgramCreate',
    component: ProgramsEdit,
  },
].map(r => {
  if (typeof r.meta === 'undefined') {
    r.meta = {
      permission: 'grains|context_CONTRIBUTOR',
      fail: '/',
    }
  }
  return r
})

export default routes
