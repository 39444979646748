import store from '@/api'
import reg from '@/helpers/regexp.js'
import { INTERNAL_GENERIC_CONTEXT_ID } from '@/api/models/Context'

const orderBy = ['order', 'title']

export default {
  data() {
    return {
      isLoadingParent: false,
    }
  },
  computed: {
    getVisibleCompetenciesTreeWithContexts() {
      const roots = []
      roots.push({
        value: this.contextId,
        label: this.getCurrentContextCompetencyTitle('title', 2),
        disabled: true,
        children: this.getContextCompetenciesTree,
      })
      if (!this.is_generic) {
        roots.push({
          value: INTERNAL_GENERIC_CONTEXT_ID,
          label: this.getGenericCompetencyTitle('title', 2),
          disabled: true,
          children: this.getVisibleGenericCompetenciesTree,
        })
      }
      return roots
    },
    getCompetenciesTreeWithContexts() {
      const roots = []
      roots.push({
        value: this.contextId,
        label: this.getCurrentContextCompetencyTitle('title', 2),
        disabled: true,
        children: this.getContextCompetenciesTree,
      })
      if (!this.is_generic) {
        roots.push({
          value: INTERNAL_GENERIC_CONTEXT_ID,
          label: this.getGenericCompetencyTitle('title', 2),
          disabled: true,
          children: this.getGenericCompetenciesTree,
        })
      }
      return roots
    },
    getContextCompetenciesTree() {
      this.$store.getters.DSCourseCompetency
      return this.getCompetenciesTree(this.is_generic)
    },
    getGenericCompetenciesTree() {
      this.$store.getters.DSCourseCompetency
      return this.getCompetenciesTree(true)
    },
    getVisibleGenericCompetenciesTree() {
      this.$store.getters.DSCourseCompetency
      let finalTree = this.getCompetenciesTree(true)
      let tree = this.getCompetenciesTree(true)
      for (let i = 1; i < this.contextGenericCompetenciesReportingLevel; i++) {
        const values = tree
          .map((el) => el.children)
          .filter(Boolean)
          .flat()
        tree = values
        finalTree = [...finalTree, ...values]
      }
      return finalTree
    },
    getVisibleRootCompetencies() {
      const roots = []
      roots.push(this.getContextCompetenciesTree.map((c) => store.get('CourseCompetency', c.value)))
      if (!this.is_generic) {
        roots.push(this.getVisibleGenericCompetenciesTree.map((c) => store.get('CourseCompetency', c.value)))
      }
      return roots.flat()
    },
  },
  methods: {
    getCurrentContextCompetencyTitle(prop = 'title', qty = 1) {
      if (this.is_generic) {
        return this.getGenericCompetencyTitle(prop, qty)
      }
      let something = this.$tc(`models.CourseCompetency.${prop}`, qty)
      something += ` ${this.contextName}`
      return something
    },
    getGenericCompetencyTitle(prop = 'title', qty = 1) {
      let something = this.$tc(`models.CourseCompetency.${prop}`, qty)
      something += ` ${this.$tc('models.Context.generic.adj', qty)}`
      return something
    },
    getCompetenciesTree(isGeneric = false) {
      this.$store.getters.DSCourseCompetency
      const setNode = (node) => {
        this.$store.getters.DSCourseCompetency
        const where = { parentId: { '==': node.id }, isGeneric }
        const childs = store.filter('CourseCompetency', { where, orderBy })
        const el = {
          value: node.id,
          label: node.title,
        }
        const children = childs.map(setNode)
        if (!this._isEmpty(children)) {
          el.children = children
        }
        return el
      }
      const where = { parentId: { '==': null }, isGeneric }
      const racines = store.filter('CourseCompetency', { where, orderBy })
      return racines.map(setNode)
    },
    getContextCompetenciesTreeNotIn(id) {
      const filterNode = (node) => {
        const comp = store.get('CourseCompetency', node.value)
        if (comp && this._includes(comp.pathId, id)) {
          node.disabled = true
        }
        if (node.children) {
          node.children = node.children.map(filterNode)
        }
        return node
      }
      const nodes = this._cloneDeep(this.getContextCompetenciesTree)
      return nodes.map(filterNode)
    },
    getExistingCourseCompetencies(query = false) {
      const notThisId = { _id: { $ne: this.id } }
      let filter = this.id ? notThisId : {}
      if (query && query.length > 0) {
        const regexp = { $regex: reg.getPermissiveRegFromString(query).$regex }
        filter = { $or: [{ title: regexp }, { code: regexp }] }
        if (this.id) {
          filter.$and = [notThisId]
        }
      }
      if (this.noParents) {
        const noParentId = { parentId: null }
        if (filter.$and) {
          filter.$and.push(noParentId)
        } else {
          filter.$and = [noParentId]
        }
      }
      this.isLoadingParent = true
      store.findAll('CourseCompetency', { filter, options: { sort: 'order' } }, { force: true }).then((records) => {
        const competencies = []
        records = this._sortBy(records, 'pathTitle')
        for (const i in records) {
          const competency = {
            label: records[i].pathTitle,
            value: records[i].id,
            disabled: this._includes(records[i].pathId, this.id),
          }

          competencies.push(competency)
        }
        this.isLoadingParent = false
      })
    },
  },
}
