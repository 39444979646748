const ClassList = () => import('./List.vue')
const ClassEdit = () => import('./Edit.vue')

export default [
  {
    path: '/class',
    name: 'ClassList',
    meta: { permission: 'grains|client_OWNER|client_ADMIN|client_TEACHER|client_SUPERVISOR' },
    component: ClassList,
  }, {
    path: '/class/certification',
    name: 'ClassCertificationList',
    meta: { permission: 'grains|client_CERTIFIER' },
    component: ClassList,
    props: { isCertification: true },
  }, {
    path: '/class/edit/:id',
    name: 'ClassEdit',
    meta: { permission: 'grains|client' },
    component: ClassEdit,
    props: true,
  }, {
    path: '/class/edit',
    name: 'ClassCreate',
    meta: { permission: 'grains|client_OWNER|client_ADMIN|client_TEACHER|client_CERTIFIER' },
    component: ClassEdit,
  }, {
    path: '/create/classes',
    name: 'CreateClass',
    meta: { permission: 'grains|client_OWNER|client_ADMIN|client_TEACHER|client_CERTIFIER' },
    component: ClassEdit,
  }, {
    path: '/create/classes/:companyId',
    name: 'CreateClassWithCompany',
    meta: { permission: 'grains' },
    component: ClassEdit,
  },
]
